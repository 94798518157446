import styled, { css } from 'styled-components';

import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';
import { atMinWidth } from '../../styles/atoms/breakpoints';

export const AuthorCardStyle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: ${props => (props.size === 'md' ? '24px' : '16px')};
`;

export const Avatar = styled.div`
  img {
    border-radius: 50%;
    width: ${props => (props.size === 'md' ? '64px' : '40px')};
    height: ${props => (props.size === 'md' ? '64px' : '40px')};
  }
`;

export const LetterAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${props => (props.size === 'md' ? '64px' : '40px')};
  height: ${props => (props.size === 'md' ? '64px' : '40px')};
`;

export const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${props => (props.size === 'md' ? '6px' : '')};
`;

export const Name = styled.p`
  color: ${colors.gray[900]};

  ${props =>
    props.size === 'md'
      ? css`
          ${font('text', 'sm', '700')}

          ${atMinWidth.sm`
            ${font('text', 'md', '700')}
          `}
        `
      : css`
          ${font('text', 'xs', '700')}
        `}
`;

export const Role = styled.span`
  color: ${colors.gray[600]};

  ${props =>
    props.size === 'md'
      ? css`
          ${font('text', 'sm', '400')}

          ${atMinWidth.sm`
            ${font('text', 'md', '400')}
          `}
        `
      : css`
          ${font('text', 'xs', '400')}
        `}
`;
